import { render, staticRenderFns } from "./CertificateBusinessIncomePage.vue?vue&type=template&id=e0c55a3c&scoped=true"
import script from "./CertificateBusinessIncomePage.vue?vue&type=script&lang=js"
export * from "./CertificateBusinessIncomePage.vue?vue&type=script&lang=js"
import style0 from "./CertificateBusinessIncomePage.vue?vue&type=style&index=0&id=e0c55a3c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0c55a3c",
  null
  
)

export default component.exports